import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from "gatsby";
import { OutboundLink } from "gatsby-plugin-gtag";
import { EuiButton, EuiSpacer } from "@elastic/eui";
import ChangelogSearch from "components/Search/IVAndroidclSearch";
import NavButtons from "components/Downloads/Mobile_Apps/NavButtons";
import BreadCrumbs from "components/Layout/BreadCrumbs";
import SEOHelmet from "components/Layout/SEOHelmet";
export const _frontmatter = {
  "title": "InstarVision Android & Blackberry App Downloads",
  "path": "/Downloads/Mobile_Apps/Android_BlackBerry/",
  "dateChanged": "2017-12-12",
  "author": "Mike Polinowski",
  "excerpt": "INSTAR Software and Documentation Downloads",
  "image": "../D_Mobile_Apps_SearchThumb.png",
  "social": "/images/Search/D_Mobile_Apps_SearchThumb.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-Downloads_white.webp",
  "chapter": "Downloads"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEOHelmet title="Download Area" dateChanged="2018-09-20" author="Mike Polinowski" tag="INSTAR IP Camera" description="Software Downloads and Usermanuals for your INSTAR Products" image="/images/Search/D_Downloads_SearchThumb.png" twitter="/images/Search/D_Downloads_SearchThumb.webp" location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE="/de/Downloadbereich/Mobile_Apps/Windows_Phone/" locationFR="/fr/Downloads/Mobile_Apps/Windows_Phone/" crumbLabel="InstarVision" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "downloads",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#downloads",
        "aria-label": "downloads permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Downloads`}</h1>
    <h2 {...{
      "id": "mobile-apps",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#mobile-apps",
        "aria-label": "mobile apps permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Mobile Apps`}</h2>
    <h3 {...{
      "id": "download-links-to-app-stores",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#download-links-to-app-stores",
        "aria-label": "download links to app stores permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Download links to app stores`}</h3>
    <OutboundLink href="https://play.google.com/store/apps/details?id=de.instar.vision" target="_blank" rel="noopener noreferrer" mdxType="OutboundLink">
  <EuiButton fill mdxType="EuiButton">Google Play Store</EuiButton>
    </OutboundLink>
    <EuiSpacer mdxType="EuiSpacer" />
    <OutboundLink href="http://www.amazon.com/INSTAR-Deutschland-GmbH-InstarVision/dp/B00LW0299C/ref=sr_1_1?s=mobile-apps" target="_blank" rel="noopener noreferrer" mdxType="OutboundLink">
  <EuiButton fill mdxType="EuiButton">Amazon Appstore</EuiButton>
    </OutboundLink>
    <EuiSpacer mdxType="EuiSpacer" />
    <OutboundLink href="https://appworld.blackberry.com/webstore/content/59960469/?lang=en" target="_blank" rel="noopener noreferrer" mdxType="OutboundLink">
  <EuiButton fill mdxType="EuiButton">Blackberry World</EuiButton>
    </OutboundLink>
    <h3 {...{
      "id": "direct-link-to-the-apk-file",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#direct-link-to-the-apk-file",
        "aria-label": "direct link to the apk file permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Direct link to the APK file`}</h3>
    <p><em parentName="p">{`(for advanced users)`}</em></p>
    <p>{`If you can not use the Google Play Store on your device, you can download the following APK file and install it manually.`}</p>
    <h4 {...{
      "id": "version-history-with-change-log",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#version-history-with-change-log",
        "aria-label": "version history with change log permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Version history with change log`}</h4>
    <ChangelogSearch mdxType="ChangelogSearch" />
    {/*
     - [1.6.2](https://wiki.instar.com/dl/InstarVisionAndroid/InstarVision_v1.6.2.zip)
     - [1.6.1](https://wiki.instar.com/dl/InstarVisionAndroid/InstarVision_v1.6.1.zip)
     - [1.6.0](https://wiki.instar.com/dl/InstarVisionAndroid/InstarVision_v1.6.0.zip)
     - [1.5.9](https://wiki.instar.com/dl/InstarVisionAndroid/InstarVision_v1.5.9.zip) [SHA265: `dfd2d58c6beb3a7ecd77ea4e258b42e0f985b47d58144f461b7776d3531e134f` InstarVision-1-5-9b4-r126.apk] \*
      - Bug fixes
     - [1.5.8](https://wiki.instar.com/dl/InstarVisionAndroid/InstarVision_v1.5.8.zip) [SHA265: `9d944cf516521d02ec22ffed56dd7b78cddfb22415b7747c702aff14ec6f338e` InstarVision-1-5-8b15.apk] \*
      - Integration of 2k+ WQHD camera models
      - INSTAR Cloud integration
      - New recording viewer
      - Bug fixes
     - [1.5.8b15](https://wiki.instar.com/dl/InstarVisionAndroid/InstarVision_v1.5.8b15.zip) [SHA265: `2f76bfe2e5084f5e183b10e9d5dcf42859cd4b97a4f55e195dda1c7e42e7a709` InstarVision-1-5-8b15.apk] \*
      - Integration of new camera models
      - INSTAR Cloud integration
      - Bug fixes
     - [1.5.8b2](https://wiki.instar.com/dl/InstarVisionAndroid/InstarVision_v1.5.8b2.zip)
      - Fixes a bug while duplicating cameras
     - [1.5.8b1](https://wiki.instar.com/dl/InstarVisionAndroid/InstarVision_v1.5.8b1.zip)
      - Fixed a bug listing available files while importing cam data
      - Fixed a bug opening exported cam data in file manager
     - [1.5.7](https://wiki.instar.com/dl/InstarVisionAndroid/InstarVision_v1.5.7.zip)
      - Support for optional unlocking the app with facial recognition
      - Fixed a bug where some video files could not be played when OpenGL renderer was active
      - Fixed a bug where some camera params could be lost when importing from a file
      - App widgets should now refresh more reliable on newer Android versions
      - Adds full access to SD / SD file download for P2P
      - SD video preloading over P2P can now be switched off in app settings
      - Fixed a bug where some images would not load completely over P2P when showing them as grid
      - Bug fixes
     - [1.5.6](https://wiki.instar.com/dl/InstarVisionAndroid/InstarVision_v1.5.6b2.zip)
      - Option to enable/disable the loading of the alarm status in multi-view (Opt-in)
      - Warning: Enabling the option might have a negative impact on the connection to your cameras
      - Bug fixes
     - [1.5.5](https://wiki.instar.com/dl/InstarVisionAndroid/InstarVision_v1.5.5.zip)
      - Advanced E-Mail / SMTP settings
      - Status icon for inactive alarm in multi-view for each HD/FHD camera
      - Bug fixes
     - [1.5.4](https://wiki.instar.com/dl/InstarVisionAndroid/InstarVision_v1.5.4.zip)
      - Redesigned wizard for setup and adding cameras
      - Improved performance on manual recordings
      - Option to send/share exported camera data
      - Option to save custom alarm schedule presets
      - Integration of new camera models
      - Bug fixes
     - [1.5.3](https://wiki.instar.com/dl/InstarVisionAndroid/InstarVision_v1.5.3b2.zip)
      - Beta Release
     - [1.5.2](https://wiki.instar.com/dl/InstarVisionAndroid/InstarVision_v1.5.2.zip) [SHA265: `c7d825a7b4b05c04d1feae9ce2deade72e7c51560b23bfb1c5bcb74af1345932`] \*
      - Option to set motion areas and advanced alarm settings
      - Advanced PTZ settings when connection over IP/DDNS
      - Bug fixes
     - [1.5.1](https://wiki.instar.com/dl/InstarVisionAndroid/InstarVision_v1.5.1.zip) [SHA265: `5972710da2ce209b407de2b119514eef8e7b210cd081adb28e3200446de72050`] \*
      - Optional fingerprint authentication on app start
      - Bug fixes
     - [1.5.0](https://wiki.instar.com/dl/InstarVisionAndroid/InstarVision_v1.5.0.zip) [SHA265: `2c46e19d9217fe2a2cf780b64ceb69dcd745c61caa4980e11d76eec73f3c0519`] \*
      - 64bit Support
      - Support for notification channels on Android 8+
      - Option to switch alarm for all cameras on multi-view
      - Security and Performance improvements
      - Bug fixes
     - [1.4.9](http://wiki.instar.com/dl/InstarVisionAndroid/InstarVision_v1.4.9.zip) [SHA265: `060ddba6253231be341fda807492814cc7351ad84e8f27a6e329416d40ad1993`] \*
      - Zoom- / Fokus- Funktion für IN-9020 über P2P
      - Option für den Neustart der Kamera
      - Bug fixes
      - Neue Funktionen für FHD P2P Kameras (benötigt neuste Firmware)
        - Durchgängige Pan/Tilt Steuerung
        - Manuelle Video-Aufnahme auf SD
     - [1.4.9b4](http://wiki.instar.com/dl/InstarVisionAndroid/InstarVision_v1.4.9b4.zip)
      - Bug fixes
     - [1.4.9b3](http://wiki.instar.com/dl/InstarVisionAndroid/InstarVision_v1.4.9b3.zip)
      - Bug fixes
     - [1.4.9b2](http://wiki.instar.com/dl/InstarVisionAndroid/InstarVision_v1.4.9b2.zip)
      - Assigned presets are now highlighted and synced with web interface
      - One step pan&tilt option available and synced with web interface
      - Camera reboot function
     - [1.4.9b1](https://wiki.instar.com/dl/InstarVisionAndroid/InstarVision_v1.4.9b1.zip)
      - Zoom and focus controls for IN-9020 over P2P
      - Custom url scheme iv:// to open the app, or cam directly by appending the name f.e. iv://6012
      - New functions for FHD P2P cameras (requires latest firmware): Continuous PT, Manual recording on SD
     - [1.4.8](https://wiki.instar.com/dl/InstarVisionAndroid/InstarVision_v1.4.8.zip) [SHA265: `b6364b1e422a356df6af2290b111102d0f5229d30d34f411e0da603318fc079c`] \*
      - Alarm schedule (advanced settings)
      - Integration of new camera models
      - Bug fixes
     - [1.4.8b5](https://wiki.instar.com/dl/InstarVisionAndroid/InstarVision_v1.4.8b5.zip)
      - New camera models added
      - Alarm schedule (advanced settings)
      - Bug fixes
     - [1.4.7b5](https://wiki.instar.com/dl/InstarVisionAndroid/InstarVision_v1.4.7b5.zip)
      - RTSP Video Fix over HTTPS
      - Camera user management added
     - [1.4.6](https://wiki.instar.com/dl/InstarVisionAndroid/InstarVision_v1.4.6.zip)
      - Automatically change the multi-view after x seconds
      - Option to configure HTTPS timeout
      - Wiki added to Help in WebView
      - Storage Locations for Foscam HD Cameras
      - Multicorrection for 32 cameras per screen
      - Fixed common bug
     - [1.4.5](https://wiki.instar.com/dl/InstarVisionAndroid/InstarVision_v1.4.5.zip)
      - Integration of 1080p camera models
      - P2Pv2 for 1080p camera models
      - Bug fixes
     - [1.4.4](https://wiki.instar.com/dl/InstarVisionAndroid/InstarVision_v1.4.4.zip)
      - Bug fixes
     - [1.4.2](https://wiki.instar.com/dl/InstarVisionAndroid/InstarVision_v1.4.2.zip)
      - Download camera SD card files
      - Selectable Record / Download Folder
      - commands can be sent to multiple cameras
      - Option for max. Display Brightness for Image Preview
      - Improved Performance
      - New third-party models
     - [1.4.1](https://wiki.instar.com/dl/InstarVisionAndroid/InstarVision_v1.4.1.zip)
      - Bug fixes
     - [1.4.0](https://wiki.instar.com/dl/InstarVisionAndroid/InstarVision_v1.4.0.zip)
      - Access SD card
      - Video recording / playback
      - Record videos to SD
      - Audio Alarm Switch
      - Digital Zoom
      - New features for P2P cameras (requires the latest firmware)
     - [1.3.8](https://wiki.instar.com/dl/InstarVisionAndroid/InstarVision_v1.3.8.zip)
      - Push notifications for all INSTAR cameras (requires webUI update)
      - Automatic change to local IP address if available (INSTAR cameras)
      - Reorder the order of cameras
      - Automatic sync option. of the camera data
      - Shorter time intervals for image preview
      - Search function for INSTAR cameras
     - [1.3.7](https://wiki.instar.com/dl/InstarVisionAndroid/InstarVision_v1.3.7.zip)
      - Bug fixes
     */}
    <br />
    <br />
    <p><em parentName="p">{`*`}{` `}<a parentName="em" {...{
          "href": "/en/Downloads/Security/"
        }}>{`What is a SHA256 Hash ?`}</a></em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      